// Colors
$primary-color: #11151C;
$soft-accent: #383838;
$mid-accent: #7D4E57;
$bright-accent: #d44d33;
$text-color: #F2F4F8;

//Fonts
$primary-font-string: 'Roboto Condensed';

//Sizes
$standardTransitionTime: 0.3s;
$standardBorderWidth: 2px;

@mixin beforeAfterStandard {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
