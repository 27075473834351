h1 {
    font-size: 30px;
    padding-top: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-style: italic;
    text-align: center;
    text-align: center;
}

.last-updated {
    font-size: 12px;
    margin-top: 5px;
}

.topic-container {
    width: 80%;
    margin-top: 30px;
}

.topic-header {
    font-size: 20px;
}

.topic-content {
    margin-top: 10px;
    font-size: 15px;
}

@media (max-width: 400px) {
    .topic-container {
        width: 90%;
    }
}
