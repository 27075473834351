@import "../styles/main_variables";

.contact-link {
  color: $bright-accent;
}

.nav-list {
  .contact-item::before {
    background-color: $bright-accent;
  }
}

h1 {
  font-size: 40px;
  padding-top: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-style: italic;
  text-align: center;
}

.contact-sub {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}

.successful-request {
  color: $bright-accent;
  font-size: 16px;
}

.contact-form-outer {
  display: flex;
  justify-content: center;
}

.contact-form {
  padding: 0 40px;
  margin-top: 50px;
  border: $standardBorderWidth solid $text-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-group {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.contact-problem {
  position: absolute;
  top: -35px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: $bright-accent;
}

.success {
  color: $bright-accent;
}

.contact-input {
  width: 80%;
}

.contact-textarea {
  width: calc(80vw * 0.8 - 8px);
  height: 120px;
  background-color: transparent;
  border: $standardBorderWidth solid $text-color;
  font-size: 15px;
  font-weight: 600;
  padding: 15px 20px;
  resize: none;

  &:focus {
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &::placeholder {
    font-size: 16px;
    font-weight: 700;
    color: white;
  }
}

.faq-section {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 14px;
  width: 80%;
  text-align: center;
}

.faq-link-bottom {
  font-size: 14px;
  text-decoration: underline;
  font-weight: 600;
  transition: color $standardTransitionTime;

  &:hover {
    color: $bright-accent;
  }
}

@media (max-width: 400px) {
  .contact-form {
    width: 90%;
  }

  .contact-input {
    width: 90%;
  }

  .contact-textarea {
    width: calc(90vw * 0.9 - 8px);
  }
}
