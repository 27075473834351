@mixin flexPosition($justify, $align, $direction) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
    flex-direction: $direction;
}

@mixin standardFlexPosition($direction) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: $soft-accent;
}

::-webkit-scrollbar-thumb {
    background: $mid-accent;
}

::-webkit-scrollbar-thumb:hover {
    background: $bright-accent;
}

body {
    background-color: $primary-color;
}

a {
    color: $text-color;
    text-decoration: none
}

.outmost-container {
    overflow-x: hidden;
}

$mainMargin: 100px;

