@import "./resets";
@import "./main_variables";
@import "./layout_styles";

//transition time variable, button-border-width variable

strong {
    font-size: inherit;
}

.standard-page-link {
    text-decoration: underline;
    font-weight: 600;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    box-shadow: 0 0 0px 1000px $primary-color inset;
    -webkit-box-shadow: 0 0 0px 1000px $primary-color inset;
    transition: background-color 5000s ease-in-out 0s;
}

//-------------------------------------------------------------------
// Dropdown
//-------------------------------------------------------------------
$dropdownWidth: 100%;

.dropdown-outer,
.dropdown-item {
    float: left;
    list-style: none;
    position: relative;
    width: 100%;
    z-index: 10;
    background-color: $primary-color;

    a {
        font-weight: 600;
        // font-family: '';
        position: relative;
        color: $text-color;
        border: $standardBorderWidth solid $text-color;
        padding: 12.5px 15px 12.5px 15px;
        display: block;
        width: $dropdownWidth;
        z-index: 100;
        width: 100%;

        i::before {
            font-size: 15px;
            position: absolute;
            right: 10px;
            top: 12px;
        }
    }

    .drop-down {
        position: absolute;
        display: none;
        left: 0;
        z-index: 0;
        width: 100%;

        li {
            position: relative;
            float: none;

            a {
                border-top: none;
                width: $dropdownWidth;


                &:hover {
                    background-color: $text-color;
                    color: $primary-color;
                }
            }
        }

        li:nth-of-type(1) a {
            border-top: none;
        }
    }
}

.accent {
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: $text-color;
    position: absolute;
    transition: $standardTransitionTime ease;
}

.animate {
    width: 100%;
    transition: $standardTransitionTime ease;
}

.item:hover {
    color: $text-color;
}

