@import "./main_variables";

//-------------------------------------------------------------------
// Buttons
//-------------------------------------------------------------------

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

//Standard Button Styles
.standard-button {
  margin-top: 20px;
  min-width: 250px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  font-family: $primary-font-string, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $text-color;
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 600;

  &:focus {
    // outline: none;
  }
}

//Drawing Button
@mixin btnBorderDrawing(
  $color,
  $hover,
  $width,
  $vertical,
  $horizontal,
  $duration
) {
  box-shadow: inset 0 0 0 $width $color;
  color: $text-color;
  transition: color $duration calc($duration/3);
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    content: "";
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;

    //interpolation - top: 0; (#{} to use variables for classnames)
    #{$vertical}: 0;
    //interpolation - left: 0;
    #{$horizontal}: 0;
  }

  &::before {
    //Ternary expression
    $h-side: if($horizontal == "left", "right", "left");
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == "top", "bottom", "top");
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    //Hier zu color ändern, die man beim Hovern haben will
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition:
        border-color 0s,
        width $duration,
        height $duration;
      width: 100%;
      height: 100%;
    }

    &::before {
      transition-delay: 0s, 0s, $duration;
    }

    &::after {
      transition-delay: 0s, $duration, 0s;
    }
  }
}

.drawing-button {
  @include btnBorderDrawing(
    $text-color,
    $bright-accent,
    $standardBorderWidth,
    bottom,
    right,
    $standardTransitionTime
  );
}

@media (max-width: 400px) {
  .standard-button {
    min-width: 220px;
  }
}
