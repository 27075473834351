@import "./main_variables";

/* form-group */
.form-group {
  position: relative;
  padding: 0;
  padding-left: 15px;
  margin: 0;

  width: 100%;
  background-color: $primary-color;
  border: $standardBorderWidth solid $text-color;
}

/* form-field */
.form-field {
  width: 100%;
  border: none;
  line-height: 32px;
  outline: 0;
  font-size: 14px;
  font-weight: 700;
  color: $text-color;
  padding: 6px 0;
  padding-right: 15px;
  background-color: transparent;
  transition: border-color $standardTransitionTime;

  &:required,
  &:invalid {
    box-shadow: none;
  }

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form-label {
    cursor: text;
    top: 12px;
  }
}

.form-field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: $standardBorderWidth;
  border-color: $bright-accent;
  border-image-slice: 1;
  
  ~ .form-label {
    position: absolute;
    top: -10px;
    display: block;
    transition: $standardTransitionTime;
    font-size: 14px;
    color: $bright-accent;
    font-weight: 700;
  }
}

/* form-label */
.form-label {
  position: absolute;
  font-family: $primary-font-string, sans-serif;
  top: -10px;
  font-size: 14px;
  display: block;
  transition: $standardTransitionTime;
  color: $text-color;
  pointer-events: none;
  font-weight: 600;
  font-size: 16px;
  left: 10px;
  padding: 0 8px;
  background-color: $primary-color;
}

/* form-confirmation-button */
.form-confirmation-button {
  font-size: 14px;
  font-family: $primary-font-string, sans-serif;
  font-weight: 700;
  position: absolute;
  width: 30%;
  height: 100%;
  right: 0px;
  margin: auto;
  line-height: 30px;
  text-align: center;
  border-left: 1px solid $text-color;
  transition: background-color $standardTransitionTime;

  &::after {
    @include beforeAfterStandard();
    background-color: $bright-accent;
    z-index: -1;
    transform: scaleX(0);
    transition: transform $standardTransitionTime ease;
    transform-origin: left;
  }

  &:hover::after {
    transform: scaleX(1);
  }
}

/* special styles for footer input */
.footer-form-group {
  margin-top: 0;
  border-width: 1px;
}

.footer-form-field {
  line-height: 30px;
  font-size: 1px;
  width: calc(70% - 15px);
}

.footer-form-label {
  font-size: 14px;
}

.form-field-v2 {
  width: calc(70% - 15px);
}
