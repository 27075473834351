@import "../styles/main_variables";

.faq-link {
  color: $bright-accent;
}

.nav-list {
  .faq-item::before {
    background-color: $bright-accent;
  }
}

h1 {
  font-size: 40px;
  padding-top: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-style: italic;
  text-align: center;
}

.faq-wrapper {
  width: 90%;
  max-width: 800px;
}

.search-section {
  position: relative;
  margin-top: 40px;
}

.faq-sub {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  width: 90%;
  left: 5%;
}

.faq-section {
  margin-top: 40px;
  padding-top: 40px;
  position: relative;
  border-top: $standardBorderWidth solid $text-color;
}

.faq-container {
  // border: $standardBorderWidth solid $text-color;
  transition: border-color $standardTransitionTime;

  .faq-question-section {
    border-bottom: $standardBorderWidth solid $text-color;
    padding: 20px 60px 20px 10px;
    position: relative;
    background-color: $primary-color;
    z-index: 5;

    .faq-question-text {
      font-size: 16px;
      font-weight: 700;
    }

    .faq-extend {
      position: absolute;
      display: flex;
      right: 20px;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;

      .faq-arrow {
        cursor: pointer;
      }

      .faq-arrow::before {
        color: $text-color;
        font-size: 20px;
      }
    }
  }

  .faq-answer-section {
    padding: 20px 10px;
    transform: translateY(-50px); 
    opacity: 0;
    height: 0; 
    transition:
      transform $standardTransitionTime,
      opacity $standardTransitionTime;

    .faq-answer-text {
      font-size: 14px;
    }
  }

  .active {
    transform: translateY(0);
    opacity: 1;
    height: 100%;
  }

  &:hover {
    border-color: $bright-accent;
  }
}

.faq-bottom {
  margin-top: 30px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: transform $standardTransitionTime;
}

.faq-bottom-text {
  font-size: 16px;
  text-align: center;
}

.faq-bottom-button {
  margin-top: 20px;
}

.hidden {
  display: none;
}

.no-results {
  font-size: 18px;
  text-align: center;
}

@media (max-width: 400px) {
  .faq-wrapper {
    width: 95%; 
  }

  .faq-container {
    .faq-question-section {
      padding: 20px 40px 20px 10px;

      .faq-extend {
        right: 10px;
      }
    }
  }
}
