@import '../styles/main_variables';

h1 {
    font-size: 30px;
    padding-top: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-style: italic;
    text-align: center;
}

.status-main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 150px;
}

.status-content {
    width: 80%;
    text-align: center;
    font-size: 16px;

    strong {
        font-size: 16px;
    }
}

br {
    content: '';
    display: block;
    margin: 10px;
}

.header-break {
    margin: 0;
}

.status-buttons {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.products-button, .contact-button {
    max-height: 50px;
}

.alternative {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
    display: none;
    text-align: center;
}

.social-media {
    width: 250px;
}

.sm-text {
    font-size: 16px;
    text-align: center;
}

.sm-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
}

.sm-button {
    border: calc($standardBorderWidth / 2) solid $text-color;
    border-radius: 30px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .lni::before {
        font-size: 15px;
        transition: color $standardTransitionTime;
    }

    &:hover > .lni::before {
        color: $bright-accent;
    }
}

//For cancelled purchase
.options {
    width: 80%;
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    flex-wrap: wrap;
}

.option:nth-of-type(1), .option:nth-of-type(3) {
    width: 25%;
}

.option:nth-of-type(2) {
    width: 50%;
}

.option-text {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
}

.option-solutions {
    display: flex;
    justify-content: center;
    border-left: $standardBorderWidth solid $text-color;
    border-right: $standardBorderWidth solid $text-color;
}

.option-solution {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .social-media {
        position: relative;
        top: -10px;
    }

    .contact-button, .products-button {
        min-width: 220px;
    }
}

@media(max-width: 1320px) {
    .option:nth-of-type(2) {
        width: 100%;
        margin-bottom: 30px;
    }

    .option-solutions {
        border-left: none;
        border-right: none;
        padding-bottom: 15px;
        border-bottom: $standardBorderWidth solid $text-color;
    }

    .option:nth-of-type(1), .option:nth-of-type(3) {
        order: 2;
        width: 50%;
    }
}

@media (max-width: 690px) {
    .status-buttons {
        flex-direction: column;
    }

    .social-media {
        margin-top: 20px;
    }

    .option-solutions {
        flex-direction: column;
        border: none;
        padding-bottom: 0;
    }

    .option-solution {
        .social-media {
            margin-top: 0;
        }
    }

    .option:nth-of-type(1), .option:nth-of-type(3) {
        order: 2;
        width: 100%;
    }

    .option:nth-of-type(3) {
        margin-top: 30px;
    }

    .option:nth-of-type(2) {
        margin-bottom: 20px;
    }

    .alternative {
        display: block;
    }
}

@media (max-width: 400px) { 
    .status-content {
        width: 90%;
    }

    .status-buttons {
        width: 90%;
    }

    .products-button {
        min-width: 220px;
    }
}
