@import "../styles/main_variables";

@mixin beforeAfterStandard {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@mixin underline {
  content: "";
  position: absolute;
  background-color: $text-color;
  width: 100%;
  height: 2px;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform $standardTransitionTime;
}

$footer-margin: 30px;

.footer-section {
  position: relative;
  display: flex;
  justify-content: center;
}

.main-content {
  min-height: 80vh;
}

.footer {
  border-top: $standardBorderWidth solid $text-color;
  background-color: $primary-color;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  width: 100%;
}

.back-to-top {
  border: $standardBorderWidth solid $text-color;
  height: 70px;
  width: 70px;
  border-radius: 40px;
  position: absolute;
  top: -35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .footer-arrow::before {
    position: absolute;
    left: 23px;
    top: 14px;
    font-size: 20px;
    animation: up 1.5s linear 0s infinite;
  }
}

.footer-logo {
  position: relative;
  margin-top: $footer-margin;
  font-weight: 700;
  font-size: 20px;
  top: 4px;
  text-align: center;
  font-style: italic;
}

.logo-image {
  width: 75px;
}

.footer-nav {
  width: 80%;
  display: flex;
  justify-content: space-around;
}

.footer-nav-item {
  position: relative;
  margin-top: $footer-margin;

  .footer-nav-link {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  &::before {
    @include underline();
  }

  &:hover::before {
    transform: scaleX(1);
  }
}

.footer-text {
  width: 80%;
  text-align: center;
  margin-top: $footer-margin;
  font-size: 12px;
}

.social-section {
  margin-top: $footer-margin;
  width: 80%;
  display: flex;
  justify-content: space-around;

  .form-confirmation-button {
    font-size: 14px;
    line-height: 42px;
    border: none;
    cursor: pointer;
    border-left: calc($standardBorderWidth / 2) solid $text-color;
    background-color: transparent;

    &::after {
      z-index: -1;
    }
  }

  .subscribe {
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin-bottom: 2px;
  }
}

.social-media-section {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .social-item {
    border: calc($standardBorderWidth / 2) solid $text-color;
    border-radius: 30px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color $standardTransitionTime;

    &:hover {
      background-color: $bright-accent;
    }
  }
}

.newsletter-section {
  width: 45%;
  .sub-notification {
    color: $bright-accent;
  }
}

.footer-confirmation-button {
  z-index: 1;
}

.footer-confirmation-button::before {
  content: "";
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: $bright-accent;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform $standardTransitionTime;
}

.footer-confirmation-button:hover::before {
  transform: scaleX(1);
}

.legal-section {
  width: 60%;
  display: flex;
  justify-content: space-around;
  margin: $footer-margin 0;

  .legal-item {
    font-size: 12px;
    color: #999;
    position: relative;

    &::before {
      @include underline();
      background-color: #999;
      height: 1px;
      cursor: pointer;
    }

    &:hover::before {
      transform: scaleX(1);
    }
  }
}

@keyframes up {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-14px);
  }
}

@media (max-width: 800px) {
  .social-section {
    flex-direction: column;
    align-items: center;

    .newsletter-section {
      width: 70%;
      margin-top: $footer-margin;
    }

    .social-media-section {
      width: 80%;
    }
  }
}

@media (max-width: 600px) {
  .social-section {
    .newsletter-section {
      width: 100%;
    }

    .social-media-section {
      width: 100%;
    }
  }

  .footer-nav {
    flex-direction: column;
    align-items: center;
    margin-top: $footer-margin - 20px;

    .footer-nav-item {
      margin-top: 20px;
    }
  }

  .legal-section {
    flex-direction: column;
    align-items: center;
    margin-top: $footer-margin - 20px;

    .legal-item {
      margin-top: 20px;
    }
  }
}

@media (max-width: 350px) {
  .social-section {
    width: 90%;
  }
}
