@import './main_variables';

*, *::before, *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: $text-color;
    scroll-behavior: smooth;
}

*:not(i) {
    font-family: $primary-font-string, sans-serif;
}

a {
    text-decoration: none;
}
