@import "../styles/main_variables";

h1,
.gallery-header {
  font-size: 40px;
  padding-top: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-style: italic;
  text-align: center;
}

.gallery-header {
  padding-top: 0;
  font-size: 25px;
  letter-spacing: 0;
  font-weight: 600;
}

.gallery-container {
  margin-top: 30px;
  column-count: 3;
  column-gap: 5px;
}

.gallery-image {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  width: 100%;
  height: auto;
  margin-bottom: 2px;
}


.about-sub {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  width: 90%;
  left: 5%;
}

.about-link {
  color: $bright-accent;
}

.nav-list {
  .about-item::before {
    background-color: $bright-accent;
  }
}

.about-outer {
  width: 90%;
  display: flex;
  max-width: 1100px;
  justify-content: center;
  margin-top: 50px;
}

.about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 0 30px;
}

.about-image {
  height: auto;
  max-width: 700px;
  width: 100%;
  filter: grayscale(100%);
  transition: filter $standardTransitionTime * 2;
}

.about-outer:hover > .about-image-container > .about-image {
  filter: grayscale(0);
}

.about-text-header {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
}

.about-text-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 0 30px;
}

.about-text-container::before {
  content: "";
  position: absolute;
  width: 2px;
  background-color: $text-color;
  height: 100%;
  left: 0px;
}

.about-text,
.gallery-description {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}

.gallery-description {
  margin-top: 20px;
  font-size: 16px;
}

.about-outer-mid {
  flex-direction: row-reverse;
}

.about-outer-mid {
  .about-text-container::before {
    left: unset;
    right: 0;
  }
}

.about-outer-mid,
.about-outer-bottom {
  margin-top: 70px;
}

.top-hr,
.bottom-hr {
  border: calc($standardBorderWidth / 2) solid $text-color;
}

.about-gallery-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-gallery {
  width: 80%;
}

.top-hr {
  margin-top: 30px;
  margin-bottom: 10px;
}

.bottom-hr {
  margin-bottom: 30px;
}

.image-item {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.image-item-inner {
  border: $standardBorderWidth solid $text-color;
  width: 90%;
  transition: border-color $standardTransitionTime;
}

.image-item-inner:hover {
  border-color: $bright-accent;
}

.text {
  text-align: center;
  font-size: 14px;
  padding: 20px;
}

.about-text-1,
.about-text-3 {
  text-align: left;
}

.instagram-section {
  width: 80%;
}

.divider {
  border-top: calc($standardBorderWidth / 2) solid $text-color;
  width: 100%;
  margin-top: 30px;
}

.bottom-text {
  margin-top: 30px;
  text-align: center;
}

.instagram-link {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.gallery-instagram::before {
  font-size: 50px;
  transition: color $standardTransitionTime;
}

.gallery-instagram:hover::before {
  color: $bright-accent;
}

@media (max-width: 1250px) {
  .about-outer {
    flex-direction: column-reverse;
    width: 95%;
    left: 2.5%;
    margin-top: 30px;
    padding-top: 20px;
    border-top: $standardBorderWidth solid $text-color;
  }

  .about-outer-mid,
  .about-outer-bottom {
    margin-top: 50px;
  }

  .instagram-section {
    width: 95%;
  }

  .about-gallery {
    width: 95%;
  }

  .about-image-container {
    width: 100%;
    margin-top: 50px;
    padding: 10px 0;
    position: relative;
  }

  .about-text-container {
    width: 100%;
    padding: 10px 0;
  }

  .about-image {
    height: auto;
    max-width: 700px;
  }

  .about-text-container::before {
    display: none;
  }

  .about-image-container::before,
  .about-image-container::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $text-color;
  }

  .about-image-container::before {
    top: -25px;
  }

  .about-image-container::after {
    bottom: -25px;
  }

  #about-image-container-bottom::after {
    display: none;
  }
}

@media (max-width: 1250px) {
  .gallery-container {
    column-count: 2;
  }
}
@media (max-width: 550px) {
  .carousel__nav {
    display: none;
  }
  .gallery-container {
    column-count: 1;
  }
}
